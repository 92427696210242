<template>
  <div id="user-profile">
    <b-overlay :show="loading">
      <b-navbar class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed">
        <slot name="navbar">
          <profile-header />
        </slot>
      </b-navbar>

      <div class="mt-2 mb-4">
        <center>
          <span style="font-size: 3.5rem; color: black" class="text-center">
            <b><span class="text-primary">Paket Tryout</span></b>
          </span>
          <br />
          <h4 class="col-sm-7 mt-2">Lorem ipsum dolor sit amet consectetur. Et egestas amet netus posuere. Aliquam mauris tellus cras tortor. Netus adipiscing urna morbi est</h4>
          <br />
          <br />
        </center>
        <div class="container-fluid" style="width: 90%">
          <b-breadcrumb class="breadcrumb-chevron">
            <b-breadcrumb-item :to="{ name: 'home' }"> Beranda </b-breadcrumb-item>
            <b-breadcrumb-item active> Paket Try Out Selengkapnya </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
      <div class="container-fluid" style="width: 90%">
        <b-row>
          <b-col md="6" xl="3" v-for="(item, i) in data" :key="i">
            <b-card
              style="cursor: pointer"
              @click="detail(item)"
              :img-src="item.thumbnail != null ? apiFile + item.thumbnail : require('@/assets/images/elp/no-image.jpg')"
              img-top
              img-alt="card img"
              :sub-title="item.sum_subtest_content + ` subtes`"
              class="mb-2"
            >
              <b-card-text>
                <b>{{ item.name }}</b>
              </b-card-text>
              <div class="card__arrow" :style="'background:' + getColor(item.category.color)"></div>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <footer-home />
    </b-overlay>
  </div>
</template>

<script>
import { BOverlay, BRow, BCol, BCard, BCardText, BCardTitle, BButton, BLink, BBreadcrumb, BBreadcrumbItem } from "bootstrap-vue";

import ProfileHeader from "./components/Navbar.vue";
import FooterHome from "./components/Footer.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
/* eslint-disable global-require */
export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BLink,
    BBreadcrumb,
    BBreadcrumbItem,
    FooterHome,
    ProfileHeader,
  },
  data() {
    return {
      data: [],
      loading: false,
    };
  },
  computed: {
    apiFile() {
      return "http://file.elp-dev.my.id/";
    },
  },

  methods: {
    getColor(color) {
      let warna = "";
      if (color == "primary") {
        warna = "blue";
      } else if (color == "success") {
        warna = "green";
      } else if (color == "danger") {
        warna = "red";
      } else if (color == "info") {
        warna = "light-blue";
      } else if (color == "warning") {
        warna = "yellow";
      }
      return warna;
    },
    getData() {
      this.loading = true;
      let params = {
        orderCol: "price",
        order: "asc",
      };
      this.$store
        .dispatch("paketTryout/index", params)
        .then((res) => {
          this.loading = false;
          this.data = res.data.data;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    detail(item) {
      window.location = `paket-detail/${item.slug}`;
    },
  },
  created() {
    this.getData();
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-profile.scss";

.card .card__arrow {
  position: absolute;
  padding: 20px;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 10px;
  bottom: 0;
  right: 0;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
